import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Navbar from './navbar'
import SocialMediaLinks from './socialMediaLinks'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = { isActive: false }
    this.toggleNavbar = this.toggleNavbar.bind(this)
  }

  toggleNavbar() {
    this.setState({ isActive: !this.state.isActive })
  }

  render() {
    const { siteTitle, shortDescription, header } = this.props
    return (
      <div>
        <Navbar
          isActive={this.state.isActive}
          toggleNavbar={() => this.toggleNavbar()}
        />

        <section className="hero hero-section">
          <div className="hero-body">
            <div className="container">
              {/* <div className="columns is-multiline is-mobile">
                {/* is-two-thirds-desktop is-two-thirds-tablet */}
              <div className="column  is-full">
                <h1 className="title">
                  <Link to="/">{siteTitle}</Link>
                </h1>
                <h2 className="subtitle">
                  {shortDescription || 'a cat mom who codes a lot'}
                </h2>
                {/* <SocialMediaLinks /> */}
              </div>
              {/* <div className="column is-full-mobile ">
                  <div className="polaroid-images">
                    <a title="@CodingCodessa">
                      <img
                        height="100"
                        src={header}
                        alt="CodingCodessa"
                        title="CodingCodessa"
                      />
                    </a>
                  </div>
                </div> */}
              {/* </div> */}
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default Header
