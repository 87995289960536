import React from 'react'
import {
  FaInstagram,
  FaGithub,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
} from 'react-icons/fa'

const SocialMediaLinks = props => {
  return (
    <div className="more-about-me-links">
      <span className="icon">
        <a href="https://twitter.com/habiba_gadalla" target="_blank">
          <FaTwitter size={props.size} />
        </a>
      </span>
      <span className="icon">
        <a
          href="https://www.linkedin.com/in/habiba-gadalla-a55494aa/"
          target="_blank"
        >
          <FaLinkedin size={props.size} />
        </a>
      </span>
      <span className="icon">
        <a href="https://www.instagram.com/habiba.gadalla/" target="_blank">
          <FaInstagram size={props.size} />
        </a>
      </span>
      <span className="icon">
        <a href="https://github.com/HabibaGadalla" target="_blank">
          <FaGithub size={props.size} />
        </a>
      </span>
      <span className="icon">
        <a href="https://www.youtube.com/c/HabibaGadalla" target="_blank">
          <FaYoutube size={props.size} />
        </a>
      </span>
    </div>
  )
}

export default SocialMediaLinks
