import React from 'react'
import Link from 'gatsby-link'

const Navbar = ({ toggleNavbar, isActive }) => (
  <nav className="navbar is-fixed-top" aria-label="main navigation">
    <div className="navbar-brand">
      <a
        className={` navbar-burger ${isActive ? 'is-active' : ''}`}
        data-target="navMenu"
        onClick={toggleNavbar}
      >
        <span />
        <span />
        <span />
      </a>
    </div>
    <div className={`navbar-menu ${isActive ? 'is-active' : ''}`} id="navMenu">
      <div className="navbar-end">
        <Link className="navbar-item" to="/">
          Home
        </Link>
        {/* 
        <Link className="navbar-item" to="/blog">
          Blog
        </Link> */}
      </div>
    </div>
  </nav>
)

export default Navbar
