import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Header from './header'
import Footer from './footer'
import './layout.scss'
import favicon from '../images/favicon.ico'
import Transition from './transition'

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        header: file(relativePath: { eq: "avatar2.png" }) {
          childImageSharp {
            fixed(width: 500) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <div className="layout">
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content: 'Habiba Gadalla - Front-End Web Developer',
            },
            {
              name: 'keywords',
              content:
                'habiba gadalla,frontend, developer, codingCodessa,coding,womenintech',
            },
            {
              name: 'google-site-verification',
              content: 'bqhoXqIMLxS38vJrJ1ZaZusIIKCzmsA8vxoYnKOyWT4',
            },
          ]}
          link={[
            {
              rel: 'icon',
              type: 'image/png',
              sizes: '16x16',
              href: `${favicon}`,
            },
          ]}
        />
        {/* <Header
          siteTitle={data.site.siteMetadata.title}
          shortDescription=""
          header={data.header.childImageSharp.fixed.src}
        /> */}
        <Transition location={location}>{children}</Transition>
        {/* {children} */}
        {/* <Footer /> */}
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  // location: PropTypes.node.location,
}

export default Layout
